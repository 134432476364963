import React, { useEffect, useState, useCallback } from 'react';
import packageJson from '../package.json';
import { DragDropContext } from 'react-beautiful-dnd';
import TierList from './TierList';
import CenteredCell from './CenteredCell';
import DraggableVirtualGrid from './DraggableVirtualGrid';
import Modal from 'react-modal';
import './App.css';

if (window.location.protocol !== 'https:') {
  window.location.href = 'https://' + window.location.host + window.location.pathname;
}

const currentVersion = packageJson.version;

const isVersionBelow = (version) => {
  const [major, minor, patch] = currentVersion.split('.').map(Number);
  const [checkMajor, checkMinor, checkPatch] = version.split('.').map(Number);

  if (major < checkMajor) return true;
  if (major === checkMajor && minor < checkMinor) return true;
  if (major === checkMajor && minor === checkMinor && patch < checkPatch) return true;
  
  return false;
};

const App = () => {
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [filter, setFilter] = useState('raid');
  const [cardsInTiers, setCardsInTiers] = useState(() => {
    const savedState = localStorage.getItem('cardsInTiers');
    return savedState ? JSON.parse(savedState) : { S: [], A: [], B: [], C: [], D: [], E: [] };
  });
  const [activeCard, setActiveCard] = useState(null);
  const [_, setEtcClickCount] = useState(0);
  const [lastClicked, setLastClicked] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('raid');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (isVersionBelow("1.0.0")) {
    localStorage.clear();
  }
  
  const handleQuestionClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    localStorage.setItem('cardsInTiers', JSON.stringify(cardsInTiers));
  }, [cards, cardsInTiers]);  

  useEffect(() => {
    window.onbeforeunload = () => {
      if (activeCard) {
        setCards(prevCards => [...prevCards, activeCard]);
        setActiveCard(null);
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [activeCard]);

  const isCardInAnyTier = useCallback((card) => {
    return Object.values(cardsInTiers).flat().some(tierCard => tierCard.id === card.id);
  }, [cardsInTiers]);

  useEffect(() => {
    fetch('/data.txt')
      .then(response => response.json())
      .then(data => {
        const initialCardsState = data.map((card, index) => ({ ...card, id: `card-${index}` }));
        setCards(prevCards => {
          if (prevCards.length === 0) {
            return initialCardsState.filter(card => !isCardInAnyTier(card));
          } else {
            return prevCards.filter(card => !isCardInAnyTier(card));
          }
        });
      })
      .catch(error => console.error('Failed to load card data:', error));
  }, [isCardInAnyTier]);
  
  useEffect(() => {
    setFilteredCards(cards.filter(card => card.category === filter && !isCardInAnyTier(card)));
  }, [cards, filter, cardsInTiers, isCardInAnyTier]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
  
    let sourceTier, cardToMove;
      
    if (source.droppableId.includes("tier")) {
      const sourceTier = source.droppableId.split('-')[1];
      cardToMove = cardsInTiers[sourceTier].find(card => card.id === draggableId);
  
      if (cardToMove) {
        setCardsInTiers(prevTiers => ({
          ...prevTiers,
          [sourceTier]: prevTiers[sourceTier].filter(card => card.id !== draggableId),
        }));
      }
    } else if (source.droppableId === "grid") {
      cardToMove = cards.find(card => card.id === draggableId);
    } else if (source.droppableId === "centered-cell") {
      cardToMove = activeCard;
    }
  
    if (destination.droppableId === "centered-cell") {
      if (activeCard && activeCard.id !== draggableId && source.droppableId !== "centered-cell") {
        setCards(prev => [...prev, activeCard]);
      }      
      setActiveCard(cardToMove);
      if (source.droppableId === "grid") {
        setCards(prev => prev.filter(card => card.id !== draggableId));
      }
    }
  
    if (destination.droppableId === "grid" && source.droppableId === "centered-cell") {
      setActiveCard(null);
      setCards(prev => [...prev, cardToMove]);
    }

    //if (destination.droppableId === "grid") {
    //  const updatedCards = Array.from(cards);
    //  const [movedCard] = updatedCards.splice(source.index, 1);
    //  updatedCards.splice(destination.index, 0, movedCard);
    //  updatedCards.sort((a, b) => a.Cid - b.Cid);
    //  setCards(updatedCards);
    //}

    if (destination.droppableId.includes("tier") && source.droppableId === "centered-cell") {
      setActiveCard(null);
      const destTier = destination.droppableId.split('-')[1];
      setCardsInTiers(prev => {
        const newTierList = Array.from(prev[destTier] || []);
        newTierList.splice(destination.index, 0, cardToMove);
        return {
          ...prev,
          [destTier]: newTierList,
        };
      });
    }
  
    if (source.droppableId === "grid" && destination.droppableId.includes("tier")) {
      const destTier = destination.droppableId.split('-')[1];
      cardToMove = cards.find(card => card.id === draggableId);
    
      setCardsInTiers(prevTiers => {
        const newTierList = Array.from(prevTiers[destTier] || []);
        newTierList.splice(destination.index, 0, cardToMove);
        return {
          ...prevTiers,
          [destTier]: newTierList,
        };
      });
    
      setCards(prevCards => prevCards.filter(card => card.id !== draggableId));
      setFilteredCards(prevFilteredCards => prevFilteredCards.filter(card => card.id !== draggableId));
    }

    if (destination.droppableId === "grid" && source.droppableId.includes("tier")) {
      sourceTier = source.droppableId.split('-')[1];
      cardToMove = cardsInTiers[sourceTier].find(card => card.id === draggableId);
      
      setCardsInTiers(prevTiers => ({
        ...prevTiers,
        [sourceTier]: prevTiers[sourceTier].filter(card => card.id !== draggableId),
      }));

      setCards(prevCards => [...prevCards, cardToMove]);
    }

    if (source.droppableId.includes("tier") && destination.droppableId.includes("tier")) {
      const destTier = destination.droppableId.split('-')[1];
      const sourceTier = source.droppableId.split('-')[1];
      cardToMove = cardsInTiers[sourceTier].find(card => card.id === draggableId);
    
      setCardsInTiers(prevTiers => {
        const newTierList = Array.from(prevTiers[destTier] || []);
        newTierList.splice(destination.index, 0, cardToMove);
        return {
          ...prevTiers,
          [destTier]: newTierList,
        };
      });
    }
    
  if (source.droppableId === destination.droppableId && source.droppableId.includes("tier")) {
    const tierName = source.droppableId.split('-')[1];
    const newCardsOrder = reorder(
      cardsInTiers[tierName], 
      source.index, 
      destination.index
    );
  
    setCardsInTiers(prevTiers => ({
      ...prevTiers,
      [tierName]: newCardsOrder,
    }));
  }
};
  
  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const handleEtcClick = () => {
    if (lastClicked !== 'etc') {
      setEtcClickCount(1);
      handleFilterClick('default')();
    } else {
      setEtcClickCount(prevCount => {
        const newCount = prevCount + 1;
        if (newCount === 20) {
          setFilter('secret');
          return 0;
        }
        return newCount;
      });
    }
    setLastClicked('etc');
    setSelectedFilter('etc');
  };

  const handleFilterClick = (newFilter) => () => {
    setEtcClickCount(0);
    setLastClicked('other');
    setFilter(newFilter);
    setSelectedFilter(newFilter);
  };
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="app">
        <TierList cardsInTiers={cardsInTiers} />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              backgroundColor: '#333',
              border: '2px solid white'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)'
            }        
          }}
        >
    <div className="modal-content">
          <h2>Welcome to OSTier!</h2>
          <i>A small project for Lost Ark OST enjoyers.</i>
          <p>
            <b>Tier List colors:</b> Type <code>flip</code> to flip the colors if you prefer S as green.
            <br />
            <br />
            <b>Bugs:</b> If you encouter some weird bug or want to reset the tier list clear your cookies
            <br />
            and reset cache for the site. Also needed to update to get new OSTs.
            <br />
            <br />
            <b>Thanks</b> to Shindrea for helping out with the categorization, and the
            <br />
            YouTube channels I (a script) grabbed the OST and images from:
          </p>
          <ul>
            <li>
              <a href="https://www.youtube.com/@STIX__" target="_blank" rel="noopener noreferrer">
                스틱스
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@lostarkglobal" target="_blank" rel="noopener noreferrer">
                Lost Ark Global
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@LOSTARK_KR" target="_blank" rel="noopener noreferrer">
                로스트아크 LOST ARK
              </a>
            </li>
          </ul>
          <p>
          <br />
            <b>Contact:</b> If an OST is missing or anything else please email me at:
            <br />
            ostier.riddance226@passmail.net
          </p>
          <p>
          <br />
            <i>Version {currentVersion}</i> <li>Breaking changes so reset whole tier list sorry if inconveniance.</li>
          </p>

        </div>
        <button onClick={closeModal} style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'none',
          border: 'none',
          color: 'white',
          fontSize: '1.5em',
          cursor: 'pointer',
        }}>
          [X]
        </button>
        </Modal>
        <CenteredCell activeCard={activeCard} />
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 3%', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <button className="filter-button" onClick={handleFilterClick('raid')} style={selectedFilter === 'raid' ? {border: '2px solid white'} : {}}>Raid</button>
            <button className="filter-button" onClick={handleFilterClick('abyssal')} style={selectedFilter === 'abyssal' ? {border: '2px solid white'} : {}}>Abyssal</button>
            <button className="filter-button" onClick={handleFilterClick('zone')} style={selectedFilter === 'zone' ? {border: '2px solid white'} : {}}>Zone</button>
            <button className="filter-button" onClick={handleFilterClick('island')} style={selectedFilter === 'island' ? {border: '2px solid white'} : {}}>Island</button>
            <button className="filter-button" onClick={handleFilterClick('orbs')} style={selectedFilter === 'orbs' ? {border: '2px solid white'} : {}}>Orb</button>
            <button className="filter-button" onClick={handleFilterClick('theme')} style={selectedFilter === 'theme' ? {border: '2px solid white'} : {}}>Theme</button>
            <button className="filter-button" onClick={handleFilterClick('wallpaper')} style={selectedFilter === 'wallpaper' ? {border: '2px solid white'} : {}}>Wallpaper</button>
            <button className="filter-button" onClick={handleEtcClick} style={selectedFilter === 'etc' ? {border: '2px solid white'} : {}}>etc</button>
          </div>
          <button className="info-button filter-button" onClick={handleQuestionClick}>?</button>
        </div>
        <DraggableVirtualGrid items={filteredCards} setCards={setCards} defaultHeight={600} defaultWidth={1000} />
      </div>
    </DragDropContext>
  );
};

export default App;
